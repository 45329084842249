var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isSaving}},[_c('ValidationObserver',{ref:"userForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
return [_c('b-toast',{attrs:{"visible":failed,"title":"Warning","variant":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('messages.invalid_form'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.isOwner || _vm.isMaster)?[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"3"}},[_c('label',{staticClass:"col-form-label"},[_vm._v("เลือก เอเย่นต์")])]),(_vm.isOwner)?_c('b-col',{attrs:{"cols":"3"}},[_c('MasterSelectInput',{attrs:{"value":_vm.form.masterId,"hide-label":""},on:{"update":_vm.onMasterIdChange}})],1):_vm._e(),(_vm.isOwner || _vm.isMaster)?_c('b-col',{attrs:{"cols":"3"}},[_c('AgentsSelectInput',{attrs:{"value":_vm.form.agentId,"master-id":_vm.form.masterId,"hide-label":"","required":""},on:{"update":_vm.onAgentIdChange}})],1):_vm._e()],1),_c('hr')]:_vm._e(),_c('ValidationProvider',{attrs:{"name":"name","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":_vm.$t('promotion_code.name'),"label-for":"name","description":"e.g. โปรโมชั่น ทดสอบ","invalid-feedback":errors[0]}},[_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"code","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":_vm.$t('promotion_code.code'),"label-for":"code","description":"e.g. CODE1234","invalid-feedback":errors[0]}},[_c('b-form-input',{attrs:{"id":"code","state":errors[0] ? false : null},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":_vm.$t('promotion_code.max_receive'),"label-for":"maxReceive"}},[_c('b-input-group',{attrs:{"append":_vm.$t('fields.times')}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-form-checkbox',{ref:"hasMaxReceive",attrs:{"name":"hasMaxReceive","switch":""},model:{value:(_vm.hasMaxReceive),callback:function ($$v) {_vm.hasMaxReceive=$$v},expression:"hasMaxReceive"}})],1),_c('b-form-input',{attrs:{"id":"maxReceive","type":"number","step":"1","disabled":!_vm.hasMaxReceive},model:{value:(_vm.form.maxReceive),callback:function ($$v) {_vm.$set(_vm.form, "maxReceive", $$v)},expression:"form.maxReceive"}})],1)],1),_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label-for":"isOncePerUser"}},[_c('b-form-checkbox',{attrs:{"name":"isOncePerUser","switch":""},model:{value:(_vm.form.isOncePerUser),callback:function ($$v) {_vm.$set(_vm.form, "isOncePerUser", $$v)},expression:"form.isOncePerUser"}},[_vm._v(" "+_vm._s(_vm.$t('promotion_code.once_per_user'))+" ")])],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"to":"/promotion-code","variant":"light"}},[_vm._v(" "+_vm._s(_vm.$t('buttons.back'))+" ")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"rounded":"","spinner-small":""}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }