<template>
  <div>
    <b-overlay :show="isSaving">
      <ValidationObserver
        ref="userForm"
        v-slot="{ handleSubmit, failed }"
    >
      <b-toast
          :visible="failed"
          title="Warning"
          variant="warning"
      >
        {{ $t('messages.invalid_form') }}
      </b-toast>

      <form @submit.prevent="handleSubmit(onSubmit)">
        <template v-if="isOwner || isMaster">
          <b-row>
            <b-col sm="12" lg="3">
              <label class="col-form-label">เลือก เอเย่นต์</label>
            </b-col>

            <b-col v-if="isOwner" cols="3">
              <MasterSelectInput
                  :value="form.masterId"
                  hide-label
                  @update="onMasterIdChange"
              />
            </b-col>
            <b-col v-if="isOwner || isMaster" cols="3">
              <AgentsSelectInput
                  :value="form.agentId"
                  :master-id="form.masterId"
                  hide-label
                  required
                  @update="onAgentIdChange"
              />
            </b-col>
          </b-row>

          <hr />
        </template>

        <ValidationProvider
            v-slot="{ errors }"
            name="name"
            :rules="{ required: true }"
        >
          <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('promotion_code.name')"
              label-for="name"
              description="e.g. โปรโมชั่น ทดสอบ"
              :invalid-feedback="errors[0]"
          >
            <b-form-input id="name" v-model="form.name" :state="errors[0] ? false : null" />
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
            v-slot="{ errors }"
            name="code"
            :rules="{ required: true }"
        >
          <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('promotion_code.code')"
              label-for="code"
              description="e.g. CODE1234"
              :invalid-feedback="errors[0]"
          >
            <b-form-input id="code" v-model="form.code" :state="errors[0] ? false : null" />
          </b-form-group>
        </ValidationProvider>

        <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="$t('promotion_code.max_receive')"
            label-for="maxReceive"
        >
          <b-input-group :append="$t('fields.times')">
            <b-input-group-prepend is-text>
              <b-form-checkbox
                  ref="hasMaxReceive"
                  v-model="hasMaxReceive"
                  name="hasMaxReceive"
                  switch
              />
            </b-input-group-prepend>

            <b-form-input
                id="maxReceive"
                v-model="form.maxReceive"
                type="number"
                step="1"
                :disabled="!hasMaxReceive"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            label-for="isOncePerUser"
        >
          <b-form-checkbox
              v-model="form.isOncePerUser"
              name="isOncePerUser"
              switch
          >
            {{ $t('promotion_code.once_per_user') }}
          </b-form-checkbox>
        </b-form-group>

        <div class="text-right">
          <b-button
              class="mr-2"
              to="/promotion-code"
              variant="light"
          >
            {{ $t('buttons.back')}}
          </b-button>
          <b-overlay
              rounded
              spinner-small
              class="d-inline-block"
          >
            <b-button
                type="submit"
                variant="primary"
                block
            >
              {{ $t('buttons.save') }}
            </b-button>
          </b-overlay>
        </div>
      </form>
    </ValidationObserver>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PromotionCodeForm',
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
  },
  props: {
    promotionCode: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isSaving: false,
      hasMaxReceive: false,
      form: {
        agentId: null,
        masterId: null,
        name: '',
        code: '',
        isOncePerUser: false,
        maxReceive: 1
      }
    }
  },
  computed: {
    ...mapGetters(['isOwner', 'isMaster']),
  },
  created() {
    if(this.promotionCode) {
      this.form = {
        agentId: this.promotionCode.agentId,
        masterId: this.promotionCode.masterId,
        name: this.promotionCode.name,
        code: this.promotionCode.code,
        isOncePerUser: this.promotionCode.isOncePerUser,
        maxReceive: this.promotionCode.maxReceive,
      }
      this.hasMaxReceive = !!this.promotionCode.maxReceive
    }
  },
  methods: {
    ...mapActions(['createPromotionCode', 'updatePromotionCode']),
    async onSubmit() {
      let payload = {
        ...this.form,
        maxReceive: this.hasMaxReceive ? +this.form.maxReceive : null
      }

      this.isSaving = true
      if(this.promotionCode) {
        // update
        await this.updatePromotionCode({
          id: this.promotionCode.id,
          payload: payload
        })
      } else {
        // create
        const id = await this.createPromotionCode(payload)
        if(id) {
          await this.$router.push(`/promotion-code/${id}`)
        }
      }
      this.isSaving = false
    },
    onAgentIdChange(agentId) {
      this.form.agentId = agentId
    },
    onMasterIdChange(masterId) {
      this.form.masterId = masterId
    },
  },
}
</script>

<style scoped>

</style>